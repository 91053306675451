<!-- 卡片信息页面 -->
<template>
  <div v-if="cardItem !== ''">
    <div class="contentTop">
      <div class="qrContent positionCenter">
        <div class="qrcanvas" @click="getCardData">
          <QRCode
            :qrurl="cardItem.healthId"
            :height="156"
            :width="156"
          ></QRCode>
          <!--          <img :src="qrImgSrc" />-->
          <!--          <img class="iconImg" src="@/assets/img/qrcode.png" />-->
          <!-- <qrcode :qrurl="qrCodeText"></qrcode> -->
        </div>
        <div class="QRlogo">
          <img src="~@/assets/img_health/logo_.png" />
        </div>
        <p class="qrText">就诊时出示此二维码</p>
      </div>
    </div>
    <div class="contentBotton">
      <p class="formText">
        <span>姓名</span>
        <span>{{ cardItem.name }}</span>
      </p>
      <p class="formText">
        <span>身份证号</span>
        <span>
          <encryption-component :textNum="cardItem.idNumber" :start="4" />
        </span>
      </p>
      <p class="formText">
        <span>就诊卡号</span>
        <span>{{ cardItem.patId }}</span>
      </p>
      <p class="formText">
        <span>电话</span>
        <span>
          <encryption-component :textNum="cardItem.phone" :start="3" />
        </span>
      </p>
    </div>
    <van-button type="primary" class="enterJZ" @click="routerTo"
      >进入卡包</van-button
    >
    <!-- <van-button type="primary" @click="setDefault">设置默认就诊人</van-button>
    <van-button type="default" size="small" @click="unbindHealthCard"
      >解绑电子健康卡</van-button
    > -->
    <van-button
      type="primary"
      class="setJZ"
      v-if="cardItem.deafult == false"
      @click="setDefault"
      >设为默认就诊人</van-button
    >
    <van-button type="primary" disabled class="setJZ-disabled" v-else
      >设为默认就诊人</van-button
    >
    <span class="unbound" @click="unbindHealthCard">解除绑定>></span>
    <!-- <a href="javasctipt;">
      <p class="psText colorhui">解绑电子健康卡</p>
    </a> -->
    <van-overlay :show="overlayState">
      <van-loading type="spinner" color="#1989fa">加载中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import encryptionComponent from "@/components/util/encryptionComponent";
import QRCode from "./qrcode";
export default {
  components: { encryptionComponent, QRCode },
  data() {
    return {
      cardItem: {},
      qrCodeText: "",
      qrImgSrc: require("@/assets/img_health/qrcode.png"),
      overlayState: false,
      openid: "",
    };
  },
  created() {
    this.cardItem = JSON.parse(sessionStorage.getItem("card_cardItem"));
    this.openid = sessionStorage.getItem("openid");
    this.getCardData();
  },
  methods: {
    // 接收通过卡列表或者绑定流程传递过来的qrCodeText，
    getCardData() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 500,
        onClose: () => {
          this.$toast.clear();
        },
      });
    },
    routerTo() {
      this.overlayState = true;
      this.$healthHttpService
        .get("healthapi/api/search/orderid", {
          qrCodeText: this.cardItem.qrCodeText,
        })
        .then((res) => {
          this.overlayState = false;
          if (res.data) {
            let orderId = res.data.orderId;
            window.location.href = `https://health.tengmed.com/open/takeMsCard?order_id=${orderId}&redirect_uri=back`;
          }
        });
    },
    setDefault() {
      this.overlayState = true;
      let postData = {
        healthid: this.cardItem.healthId,
        //userid: this.cardItem.openid,
        userid: this.openid,
        type: 1,
      };
      this.$http.put("/api/card/default", postData).then((res) => {
        this.overlayState = false;
        if (res && res.status === 200 && res.data) {
          this.$toast.loading({
            message: "设置成功！",
            forbidClick: true,
            duration: 500,
            onClose: () => {
              this.$router.go(-1);
            },
          });
        } else {
          this.$toast.loading({
            message: "设置失败！",
            forbidClick: true,
          });
        }
      });
    },

    /*
      解除电子健康卡绑定
    */
    unbindHealthCard() {
      this.overlayState = true;
      let postData = {
        healthid: this.cardItem.healthId,
        //userid: this.cardItem.openid,
        userid: this.openid,
        type: 1,
      };
      this.$http
        .put("/api/card/unbind", postData)
        .then((res) => {
          this.overlayState = false;
          if (res && res.status === 200 && res.data) {
            // 如果删除的是当前就诊人，将当前就诊人缓存清空
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (CurrentJZPerson) {
              console.log(CurrentJZPerson);
              if (this.cardItem.healthId == CurrentJZPerson.healthId) {
                sessionStorage.removeItem("CurrentJZPerson");
              }
            }
            this.$toast.loading({
              message: "解绑成功！",
              forbidClick: true,
              duration: 500,
              onClose: () => {
                this.$router.go(-1);
              },
            });
          } else {
            this.$toast.loading({
              message: "解绑失败！",
              forbidClick: true,
            });
          }
        })
        .catch((err) => {
          console.log();
          this.$toast.loading({
            message: "解绑失败！",
            forbidClick: true,
          });
        });
    },
  },
};
</script>
<style scoped>
.wrap {
  background: transparent;
}
.contentTop {
  height: 5.5rem;
  width: 90%;
  background-color: #fff;
  margin: 10px auto;
  border-radius: 0.2rem;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.qrContent {
  height: 4.5rem;
  width: 4rem;
  position: relative;
}
.qrcanvas {
  position: relative;
  width: 100%;
  height: 4rem;
}
.qrText {
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.2rem;
  color: rgb(170, 170, 170);
}
.QRlogo {
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 2rem;
  left: 2rem;
  transform: translate(-50%, -50%);
}
.contentBotton {
  width: 90%;
  height: 4rem;
  background-color: #ccc;
  border-radius: 0.2rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  overflow: hidden;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.formText {
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  font-weight: bold;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.7rem;
}
.enterJZ {
  margin: auto;
  border-radius: 5px;
  position: relative;
  width: 4rem;
  left: -0.3rem;
  border: none;
  background-image: linear-gradient(to right, #57fdc4, #00d48b);
  margin-top: 30px;
}
.setJZ {
  margin: auto;
  border-radius: 5px;
  position: relative;
  width: 4rem;
  left: 0.3rem;
  border: none;
  background-image: linear-gradient(to right, #6dc0ff, #189bff);
  margin-top: 30px;
}
.setJZ-disabled {
  margin: auto;
  border-radius: 5px;
  position: relative;
  width: 4rem;
  left: 0.3rem;
  border: none;
  background-color: #afafaf;
  margin-top: 30px;
}
.unbound {
  display: block;
  margin: 0.5rem auto;
  color: #44aefe;
  border: none;
  font-size: 0.4rem;
}
</style>
