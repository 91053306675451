<!-- 二维码生成组件 -->
<template>
  <div ref="qrcodeContainer">
    <div class="QRlogo positionCenter">
      <img src="~@/assets/img_health/logo_.png" />
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "qrcode",
  props: {
    qrurl: {
      type: String,
      default: () => {},
    },
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    },
  },
  data() {
    return {};
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    let qrcode = new QRCode(this.$refs.qrcodeContainer, {
      width: this.width,
      height: this.height,
      text: this.qrurl, // 二维码的内容
      correctLevel: QRCode.CorrectLevel.H,
    });
  },
};
</script>
<style scoped>
.QRlogo {
  width: 0.36rem;
  height: 0.36rem;
  position: absolute;
}
</style>
